<template>
  <div v-if="!loading">
    <div><img :src="blogpost.heroImage.url" :alt="blogpost.heroImage.title"></div>
    <h2>{{ blogpost.title }}</h2>
    <div>{{ blogpost.body }}</div>
    <div>{{ blogpost.author.name }}</div>
    <div>{{ blogpost.publishDate }}</div>
    <div>{{ blogpost.tags }}</div>
  </div>
</template>

<script>
  import BlogPostService from '@/services/BlogPostService.js';
  export default {
    name: 'BlogPostSingle',
    data() {
      return {
        blogpost: {},
        loading: false
      }
    },
    components: {},
    created(){
      this.getBlogPostData();
    },
    methods: {
      async getBlogPostData(){
        this.loading = true;
        BlogPostService.getBlogPostSingle(this.$route.params.id)
          .then(
            (blogpost => {
              this.$set(this, "blogpost", blogpost);
              this.loading = false;
            }).bind(this)
          );
      }
    }
  } 
</script>

<style lang="sass" scoped>
</style>