import axios from "axios"

export default {
  async getBlogPostShortData() {
    let res = await axios.get("https://tpagembira.azurewebsites.net/");
    return res.data.blogPostCollection.items;
  },
  async getBlogPostSingle(blogPostId) {
    let res = await axios.get("https://tpagembira.azurewebsites.net/blogpost/" + blogPostId);
    return res.data.blogPost;
  },
  async getHomePost(){
    let res = await axios.get("https://tpagembira.azurewebsites.net/home/");
    return res.data.blogPost;
  }
}